import Store from "@/redux/store";
import { Provider } from "react-redux";
import PageHead from "../Head";
import BackToTop from "../backToTop";

import UniversityClassic from "@/components/13-university-classic/13-University-Classic";
import Separator from "@/components/Common/Separator";
import FooterOne from "@/components/Footer/Footer-One";
import HeaderStyleFour from "@/components/Header/HeaderStyle-Four";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";
import Context from "@/context/Context";
// import { GoogleLogin } from 'react-google-login';


// import { LoginProvider } from "@/context/LoginContext";

// const responseGoogle = (response) => {
//   // const { isSignup, createNewUser, onAuth } = this.props
//   const userProfileObj = response.profileObj
//   console.log('response', userProfileObj)
//   // if (userProfileObj) {
//   //   this.setState(
//   //     {
//   //       isLoggedIn: true,
//   //       // userId: userProfileObj.googleId,
//   //       name: userProfileObj.givenName,
//   //       email: userProfileObj.email,
//   //       picture: userProfileObj.imageUrl,
//   //     },
//   //     () => {
//   //       if (isSignup) {
//   //         createNewUser({
//   //           accessToken: response.accessToken,
//   //           confirmPassword: '',
//   //           password: '',
//   //           email: response.profileObj.email,
//   //           firstName: response.profileObj.givenName,
//   //           imgUrl: response.profileObj.imageUrl,
//   //           lastName: response.profileObj.familyName,
//   //           loginType: 'gmail',
//   //           mobileNumber: 'string',
//   //         })
//   //       } else {
//   //         onAuth(userProfileObj)
//   //       }
//   //     }
//   //   )
//   // } else {
//   //   this.setState({
//   //     isLoggedIn: false,
//   //   })
//   // }
// }


const UniversityPage = () => {
  return (
    <>
      <PageHead title="Best Cosmetology and Beauty School in Calgary - GD College" />
      {/* <LoginProvider> */}
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleFour headerType="" />
          <UniversityClassic />
          <Cart />
          <BackToTop />
          <Separator />
          
          <FooterOne />
        </Context>
      </Provider>
      {/* </LoginProvider> */}
    </>
  );
};

export default UniversityPage;
