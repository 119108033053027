import { useEffect } from "react";

const HubSpotForm = () => {  
    useEffect(() => {    
      const script = document.createElement('script'); 
         script.src = 'https://js.hsforms.net/forms/v2.js'; // HubSpot Forms API URL   
         script.async = true;    
         script.onload = () => {     
           if (window.hbspt) {        
            window.hbspt.forms.create({         
               portalId: '46151403', // Replace with your HubSpot Portal ID          
               formId: '68996e19-baaa-4c04-944d-d08e65d2b87e', // Replace with your HubSpot Form ID          
               target: '#hubspotForm'        
              });      
            }    
          };    
          document.body.appendChild(script);  
        }, []);  
        return <div id="hubspotForm"></div>;
      };
      export default HubSpotForm;