import Head from "next/head";

const PageHead = ({ title }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="Explore GD College, the best cosmetology and beauty school in Calgary. Gain practical skills and knowledge from industry professionals to excel in your beauty career." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Explore GD College, the best cosmetology and beauty school in Calgary. Gain practical skills and knowledge from industry professionals to excel in your beauty career." />
        <meta property="og:image" content="/public/images/favicon.png" />
        <link rel="icon" href="favicon.png" />
        <link rel="icon" href="/pages/favicon.png" />
        {/* <GoogleTagManager gtmId="GTM-NCWP76SF" /> */}
      </Head>
    </>
  );
};

export default PageHead;
