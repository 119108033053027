import HeaderTopFour from "./Header-Top/HeaderTop-Four";
import HeaderFour from "./Headers/Header-Four";

import Link from "next/link";

const HeaderStyleFour = () => {
  return (
    <>
      <header className="rbt-header rbt-header-4">
        <div className="rbt-sticky-placeholder"></div>
        {/* <HeaderTopFour
          bgColor="bg-color-white border-top-bar-primary-color rbt-border-bottom"
          gapSpaceBetween="header-space-betwween"
          container="container-fluid"
          flexDirection=""
          btnClass="rbt-switch-btn btn-gradient btn-xs"
          btnText="Call us now"
          sticky="bg-color-white header-sticky"
        /> */}

        <div
          className="header-info"
        >
          <ul className="social-share-transparent">
            <li>
              <Link href="https://www.facebook.com/GDCollege9/">
                <i className="fab fa-facebook-f"></i>
              </Link>
            </li>
            <li>
              <Link href="https://ca.linkedin.com/company/gdcollege">
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </li>
            <li>
              <Link href="https://www.instagram.com/gd_college/">
                <i className="fab fa-instagram"></i>
              </Link>
            </li>
            <li>
              <Link href="https://www.youtube.com/@gdcollege">
                <i className="fab fa-youtube"></i>
              </Link>
            </li>
            <li className="tiktok-icon">
              <Link href="https://www.tiktok.com/@gdcollege3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                </svg>
              </Link>
            </li>
          </ul>
        </div>

        <HeaderFour
          sticky="header-sticky"
          gapSpaceBetween="header-space-betwween"
          flexDirection=""
          container="container-fluid"
          navigationEnd="rbt-navigation-start"
          btnClass="rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none"
          btnText="Enroll Now"
        />
      </header>
    </>
  );
};

export default HeaderStyleFour;
